// External imports
import styled from '@emotion/styled'

// Constants
import { MOBILE_WIDTH_PX, TABLET_WIDTH_PX } from '../constants'
import { TITLES, DEFAULT } from '../font'

const MAX_WIDTH_PX = 1172

export const HeroSection = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH_PX}px;
  margin: 0 auto;
  padding: 96px 64px 128px;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    width: 80%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 80px;
  }

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    padding-top: 48px;
    padding-bottom: 64px;
  }
`

export const FlexHeroSection = styled(HeroSection)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    flex-direction: column;
  }
`

export const FlexItem = styled.div`
  flex: 1 1 0%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 16px;

  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    align-items: center;
    text-align: center;
    margin: 0 0 32px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const Title = styled.h1`
  ${TITLES[1100]}
  color: ${(props): string => props.theme.outwrite.colors.black};
  margin: 0 0 16px 0;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${TITLES[900]}
    margin-bottom: 8px;
  }
`

export const Description = styled.p`
  ${DEFAULT[600]}
  color: ${(props): string => props.theme.outwrite.colors.gray[900]};
  margin: 0 0 64px 0;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${DEFAULT[300]}
  }
`
