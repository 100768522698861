import type { OutwriteShadesBrandColor } from '@outwrite/themes'

import styled from '@emotion/styled'

export type HighlightColor = keyof OutwriteShadesBrandColor

/**
 * Component properties
 */
interface HighlightProps {
  readonly color?: HighlightColor
}

/**
 * Component
 */
const Highlight = styled.mark<HighlightProps>`
  position: relative;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 1px 55%;
  background-size: calc(100% - 2px) 50%;
  background-image: linear-gradient(
    to right,
    ${(props): string => props.theme.outwrite.colors[props.color ?? 'purple']['300']} 0%,
    ${(props): string => props.theme.outwrite.colors[props.color ?? 'purple']['300']} 100%
  )
`

export default Highlight
