// Type imports
import type React from 'react'
import type { SerializedStyles } from '@emotion/react'
import type { OutwriteShadesBrandColor } from '@outwrite/themes'
import type { LinkTypes } from '../types'

// External imports
import Image from './NextImage'
import styled from '@emotion/styled'

// HOCS
import persistQueriesLink from '../hocs/persist-queries-link'

// Util
import { isAppLink, isExternalLink, isInternalLink } from '../util/type-guards'

// Constants
import { DEFAULT } from '../font'
import { MOBILE_WIDTH_PX, TABLET_WIDTH_PX } from '../constants'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin-top: 96px;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    margin-top: 64px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    margin-top: 40px;
  }
`

interface GridProps {
  readonly cols?: number // By default 3
  readonly responsiveCols?: {
    readonly tablet: number
    readonly mobile: number
  }
}

export const Block = styled.div<GridProps>`
  flex-basis: ${(props): number => 100 / (props.cols ?? 3)}%;
  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    padding-left: 16px;
    padding-right: 16px;
    flex-basis: ${(props): number => 100 / (props.responsiveCols?.tablet ?? ((props.cols ?? 3) - 1))}%;
  }

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${(props): string => props.responsiveCols?.mobile ? `flex-basis: ${100 / props.responsiveCols.mobile}%;` : ''}
    padding: 20px;
    align-items: center;
    text-align: center;
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex-shrink: 0;
  flex-grow: 0;
`

interface ColorProps {
  readonly bgColor?: string
}

export const IconWrapper = styled(ImgWrapper)<ColorProps>`
  width: 84px;
  height: 84px;

  border-radius: 100%;
  background-color: ${(props): string => {
    const bgColor = props.theme.outwrite.colors[(props.bgColor ?? 'yellow') as keyof OutwriteShadesBrandColor] ?? props.theme.outwrite.colors.yellow
    return bgColor[300]
  }};

  padding: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    width: 48px;
    height: 48px;
    padding: 10px;
    margin-bottom: 16px;
  }
`

const UNDERLINE_HEIGHT = '3px'

const Title = styled.p<ColorProps>`
  ${DEFAULT[600]}
  font-weight: 600;
  padding-bottom: 2px;
  margin: 0 0 6px 0;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    font-size: 16px;
    line-height: 24px;
  }

  position: relative;

  & span {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 0% ${UNDERLINE_HEIGHT};
    background-image: linear-gradient(
      to right,
      ${(props): string => {
        const bgColor = props.theme.outwrite.colors[(props.bgColor ?? 'yellow') as keyof OutwriteShadesBrandColor] ?? props.theme.outwrite.colors.yellow
        return `${bgColor[500]} 0%, ${bgColor[500]} 100%`
      }}
    );
    transition: background-size ${(props): string => props.theme.transitionDuration};
  }
`

const StyledLink = persistQueriesLink(
  styled.a`
    color: ${(props): string => props.theme.outwrite.colors.gray[900]};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
      align-items: center;
    }
    &:hover {
      ${Title} span {
        background-size: 100% ${UNDERLINE_HEIGHT};
      }
    }
  `
)

interface DisplayProps {
  readonly visibleOnMobile?: boolean
}

const Description = styled.p<DisplayProps>`
  ${DEFAULT[500]}
  color: ${(props): string => props.theme.outwrite.colors.gray[700]};
  margin: 0;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${(props): string | SerializedStyles => props.visibleOnMobile
      ? DEFAULT[200]
      : 'display: none;'
    }
  }
`

interface Img {
  readonly alt: string
  readonly src: string
}

type Content = JSX.Element | string

interface BaseFeatureBlock extends ColorProps {
  readonly title: Content
  readonly description: Content
  readonly icon?: Img
  readonly img?: Img
}

export type FeatureBlock = BaseFeatureBlock & Partial<LinkTypes>

export interface ImageComponentProps {
  readonly block: FeatureBlock
  readonly index: number
}

interface OwnProps {
  readonly list: FeatureBlock[]
  readonly descVisibleOnMobile?: boolean
  readonly GridComponent?: React.FC
  readonly BlockComponent?: React.FC<GridProps>
  readonly ImageComponent?: React.FC<ImageComponentProps>
}

type Props = OwnProps & Partial<GridProps>

const FeatureGrid: React.FC<Props> = ({
  GridComponent = Grid,
  BlockComponent = Block,
  ImageComponent,
  list,
  descVisibleOnMobile,
  cols,
  responsiveCols,
  ...rest
}) => {
  return (
    <GridComponent {...rest}>
      {list.map(({ title, description, bgColor, icon, img, ...linkProps }, i) => {
        const children = (
          <>
            {ImageComponent
              ? <ImageComponent block={list[i]} index={i} />
              : img
                ? (
                  <ImgWrapper>
                    <Image src={img.src} alt={img.alt} width={300} height={300} />
                  </ImgWrapper>
                  )
                : icon && (
                  <IconWrapper bgColor={bgColor}>
                    <Image src={icon.src} alt={icon.alt} width={60} height={60} />
                  </IconWrapper>
                )}
            <Title bgColor={bgColor}><span>{title}</span></Title>
            <Description visibleOnMobile={descVisibleOnMobile}>{description}</Description>
          </>
        )
        return (
          <BlockComponent key={typeof title === 'string' ? title : i} cols={cols} responsiveCols={responsiveCols}>
            {(isInternalLink(linkProps) || isExternalLink(linkProps) || isAppLink(linkProps))
              ? <StyledLink {...linkProps}>{children}</StyledLink>
              : children
            }
        </BlockComponent>
        )
      })}
    </GridComponent>
  )
}

export default FeatureGrid
